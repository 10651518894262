<template>
  <div class="fixed inset-0 overflow-hidden transition-all duration-100 ease-in-out" style="z-index:9999" :class="{ 'block': isOpen, 'hidden': !isOpen, }">
    <div class="absolute inset-0 bg-gray-500 opacity-50" @click="close"></div>
    <div class="absolute inset-y-0 right-0 max-w-full flex drawer_height">
      <div class="relative w-screen ">
        <div class="h-full bg-white overflow-x-auto max-w-full drawerDropdown">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    handleClickOutside(e) {
      if (this.isOpen && !this.$el.contains(e.target)) {
        this.close();
      }
    },
  },
  mounted() {

  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.block {
  display: block;
}
.drawerDropdown{
  bottom: 0px !important;
  position: fixed;
  width: 100%;
  height: 270px;
}
</style>
